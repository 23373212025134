
import { Vue, Component, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
import { ClasseComposicao } from '@/core/models/orcamentoObras';
import { ClasseComposicaoService } from '@/core/services/orcamentoObras';
import GanttComponent from "@/components/obras/cronograma/GanttComponent.vue";

@Component({
  components: {    
    GanttComponent
  }
})
 
export default class ListaComposicao extends mixins(Vue,ListPage) {
  public service =  new ClasseComposicaoService();
  public item = new ClasseComposicao();

  tasks = {
    data: [
      {id: 1,text: "Etapa 1", start_date: "2019-12-19", duration: 10,progress: 0.6, parent:0},
      {id: 6,text: "Etapa 1", start_date: "2019-12-28", duration: 10,progress: 0.6, parent:1},
      {id: 2,text: "Etapa 2", start_date: "2020-01-20",duration: 3,progress: 0.4, parent:0},
      {id: 3,text: "Etapa filho 1",start_date: "2020-01-20",duration: 8,progress: 0.4,parent:2},
      {id: 4,text: "Etapa filho 2", start_date: "2020-01-20" ,duration: 8,progress: 0.4,parent:2},
      {id: 5,text: "Etapa filho 3", start_date: "2020-01-20" ,duration: 10,progress: 0.4,parent:2}
    ],
    links: [
      { id: 3, source: 2, target: 3, type: "1" },
      { id: 4, source: 2, target: 4, type: "1" }, 
      { id: 5, source: 2, target: 5, type: "1" }
    ],
  };

  selectedTask:any = null;
  messages:any = [];

  get progressPercentage() {
    let taskProgress = this.selectedTask.progress;
    if (!taskProgress) {
      return "0";
    }
    return `${Math.round(+taskProgress * 100)} %`;
  }

  get formattedStartDate() {
    let taskStart = this.selectedTask.start_date;
    return `${taskStart.getFullYear()} / ${taskStart.getMonth() + 1} / ${taskStart.getDate()}`;
  }
  
  get formattedEndDate() {
    let taskEnd = this.selectedTask.end_date;
    return `${taskEnd.getFullYear()} / ${taskEnd.getMonth() + 1} / ${taskEnd.getDate()}`;
  }

  selectTask(task) {
    this.selectedTask = task;
  }

  addMessage(message) {
    this.messages.unshift(message);
    if (this.messages.length > 40) {
      this.messages.pop();
    }
  }

  logTaskUpdate(id, mode, task) {
    let text = task && task.text ? ` (${task.text})` : "";
    let message = `Task ${mode}: ${id} ${text}`;
    this.addMessage(message);
  }

  logLinkUpdate(id, mode, link) {
    let message = `Link ${mode}: ${id}`;
    if (link) {
      message += ` ( source: ${link.source}, target: ${link.target} )`;
    }
    this.addMessage(message);
  }

  empreendimentos = [];
  titulo: string = 'Gantt';
  subTitulo: string = 'cadastrados no Sistema';


  mounted(){

  }
 }

